import React from 'react';
import { graphql } from 'gatsby';
import {
  Box,
  Layout,
  Container,
  Section,
  RawHTML,
  CaseStudy,
  Article,
} from '../components';

export default function StudyCaseTemplate({ data }) {
  const pageData = data?.wpgraphql?.caseStudy ?? {};

  return (
    <Layout
      title={pageData.title}
      description={
        pageData.case_fields.caseStudyMetaDescription || pageData.content
      }
      image={pageData.featuredImage.node.mediaItemUrl}
    >
      <Container mt={{ _: 8, md: 10 }}>
        <Box
          maxWidth={{ _: '100%', md: '900px' }}
          position="relative"
          pt={3}
          pl={{ _: 2, md: 0 }}
          mx="auto"
        >
          <CaseStudy type="large" caseStudy={pageData} isLink={false} />
        </Box>
        <Section mt={{ _: '60px', md: '80px' }}>
          <Article>
            <RawHTML html={pageData.content} />
          </Article>
        </Section>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      caseStudy(id: $id) {
        id
        title
        slug
        content
        featuredImage {
          node {
            altText
            mediaItemUrl
          }
        }
        case_fields {
          caseStudyMetaDescription
        }
      }
    }
  }
`;
